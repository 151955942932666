import React from "react"

import Accordion from "react-bootstrap/Accordion"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"

import Banner from "./banner"

const questions = {
  general: [
    {
      question: "What is Scratchwork?",
      answer: `
        Scratchwork is a digital whiteboard built by mathematicians to accommodate students, teachers, and researchers who don't have a tablet.
        Subjects like math, physics, chemistry, music, etc. rely on free-form drawings to communicate complex ideas through expressions, equations, and diagrams.
        Scratchwork makes it easier to quickly input these ideas onto a virtual board, edit them, and share them with others.
      `
    },
    {
      question: "Do I need a tablet to use Scratchwork?",
      answer: `
        You don't! If you prefer to draw your ideas on paper, please do that!
        Scratchwork can use a webcam or phone camera to scan drawings from your paper and place them on a digital board.
        Once on the board, they can be copied, rearranged, edited, and deleted as if they had been drawn on a tablet with a stylus.
        Since most students don't have access to a tablet, this unique feature makes Scratchwork ideal for education.
      `
    },
    {
      question: "Can I use a tablet with Scratchwork?",
      answer: `
        Of course! We recognize that some people already have a tablet and are most comfortable using it to jot down their ideas.
        Although the Scratchwork app is browser based, we have designed it to handle tablet-specific stylus and touch inputs.
      `
    },
    {
      question: "How far along is Scratchwork?",
      answer: `
        As of early fall 2020, Scratchwork is in a late-stage beta.
        It has already been used to effectively teach in high school, college, and private tutoring sessions.
        We saw a surge in usage due to COVID-19 and are working hard to accommodate the many new needs of our users.
        We expect our product to mature considerably during the fall semester.
      `,
    },
    {
      question: "What is the best way for teachers to use Scratchwork?",
      answer: `
        The most popular way teachers use Scratchwork is to have their students work in small groups to solve problems.
        Especially due to COVID-19, Scratchwork is a unique way for students to continue active learning even if they can't all work together at the same physical table.
        By having multiple tabs open to different boards, teachers can quickly switch back and forth between student groups.
      `,
    },
  ],
  pricing: [
    {
      question: "It seems I can do almost anything with the free plan. Why would I consider the paid plan?",
      answer: `
        Right now, the paid plan offers a level of reliability that schools should strive to provide their students.
        When we received a surge in demand and feature requests due to COVID-19, we decided to prioritize helping all our users over collecting payment and putting restrictions in place.
        In particular, we made almost all features temporarily available for free.
        Over the upcoming months, as we continue to improve our app, we will begin transitioning to a more profit-sustaining business.
        During this transition time, our paying users will be given access to a private website that will be less susceptible to disruption than the public one.
        If your school wishes to offer a consistent educational experience, you will need to be on the paid plan.
      `,
    },
    {
      question: "What happened to the individual pricing plans?",
      answer: `
        Some of our early users may recall an earlier pricing plan that was more oriented to individuals.
        We found that, especially as COVID-19 hit, the vast majority of our customers are educators, and we decided to switch to a pricing model that is most natural for them.
        We eventually intend to provide additional plans that are more focused on individual or corporate use.
      `,
    },
    {
      question: "What if I'm already on an existing paid plan?",
      answer: <>
        You should have received an email from us. If you have not or have more questions, please contact us
        at <a href="mailto:contact@scratchwork.io">contact@scratchwork.io</a>.
      </>,
    },
  ],
  features: [
    {
      question: "Why can't I have more than 7 simultaneous editors on one board on the paid plan?",
      answer: `
        At the moment, this is a technical limitation.
        We have found that larger group sizes can sometimes experience a slowdown when too many people are working at once.
        We are working to eventually support full classes.
      `,
    },
    {
      question: "What happened to the LaTeX pen?",
      answer: `
        Due to COVID-19, we had a surge in users which made this feature too costly to support and we disabled it in March 2020.
        It is currently not offered on any plan.
        We hope to bring it back soon for paying users, especially once we are able to work out the economics of this feature.
      `,
    },
  ],
}

const QuestionGroup = ({title, questions}) => (
  <>
    <h2 style={{marginTop:"50px"}}>{title}</h2>
    <Accordion>
      {
        questions.map((question, index) => (
          <Card key={question.question}>
            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} style={{textAlign:"left", whiteSpace:"normal"}}>
              {question.question}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${index}`}>
              <Card.Body>{question.answer}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))
      }
    </Accordion>
  </>
);

export default () => (
  <>
    <Banner title="Frequently Asked Questions" link="/faq" />
    <div style={{margin:"0px auto 50px auto", padding:"15px", textAlign:"justify", maxWidth:"700px"}}>
      <QuestionGroup title="General" questions={questions.general} />
      <QuestionGroup title="Pricing" questions={questions.pricing} />
      <QuestionGroup title="Features" questions={questions.features} />
    </div>
  </>
)
